<template>
    <!-- create dialog using vuetify for data entry -->
    <v-dialog width="98%" :value="dialogValue" persistent>
      <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
      >
        <v-card class="pt-8">
          <v-card-text style="height: 80vh">
            <VRow v-if="!isDis">
              
              <v-col md="3">
                <v-text-field dense  :label="$t('from-date-bill')" type="date" outlined
                              v-model="form.created_from">
                </v-text-field>
              </v-col>

              <v-col md="3">
                <v-text-field dense  :label="$t('to-date-bill')" type="date" outlined
                              v-model="form.created_to">
                </v-text-field>
              </v-col>
              <v-col md="3">
                <v-autocomplete :items="childrens" item-text="name" item-value="id"
                                v-model="form.student" :search-input.sync="studentSearch"
                                :loading="childrensLoading" :label="$t('student name')"
                                :hint="$t('requird')"></v-autocomplete>
              </v-col>
              <v-col md="3">
                <v-autocomplete :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
            item-value="id" :loading="costCenterItemsLoading" v-model="form.cost_center"
            @keypress="fetchAutoCompleteCostCenter" no-filter clearable>
          </v-autocomplete>
              </v-col>
              
            </VRow>
            <v-row v-if="!isDis">
              <v-col md="3">
                <v-text-field dense  :label="$t('from-due-date')" type="date" outlined
                              v-model="form.due_from">
                </v-text-field>
              </v-col>

              <v-col md="3">
                <v-menu
          ref="menu1"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            :label="$t('to-due-date')"
              outlined
              dense
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              v-model="form.due_to"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.due_to"
            no-title
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
                
              </v-col>
              <v-col md="3">
                <v-text-field dense  :label="$t('numbill')" type="text" outlined
                              v-model="form.invoice">
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-btn color="blue" style="width: 100%; color: white" @click="submit" type="button">{{
                    $t('hr.payment.grab')
                  }}
                </v-btn>
              </v-col>
                <v-col md="1">
                <v-btn   @click="cancelPay" type="button">{{
                    $t('cancel')
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-8" v-if="!isDis"></v-divider>
            <v-form ref="form2" @submit.prevent="submit" lazy-validation>
              <v-row v-if="isThereCalculation">
                <v-col sm="3">
                  <v-autocomplete :disabled="isDis" dense @update:search-input="handleSearch($event)" outlined :items="cashs"
                                  v-model="paymentSubmit.cash_account_id" item-text="name"
                                  :label="$t('hr.loan.cash')"
                                  item-value="id" :rules="requiredRule()"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-text-field dense outlined disabled v-model="summary"
                                :label="$t('hr.payment.summary')"></v-text-field>
                </v-col>
                <v-col sm="2" v-if="!isDis">
                  <v-btn  width="100%" @click="submitPayments" :disabled="!isThereCalculation" class="mx-1"
                         style="color: white" color="blue">
                    {{ $t('save') }}
                  </v-btn>
                </v-col>
                <v-col sm="2">
                  <v-btn width="100%" @click="cancelPay" :disabled="!isThereCalculation" outlined class="mx-1"
                         >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
               
              </v-row>
              <v-row v-if="isThereCalculation">
                <v-col sm="3">
                  <v-menu
                  :disabled="isDis"
          ref="menu1"
          v-model="dateMenu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }" :disabled="isDis">
            <v-text-field
            :label="$t('date')"
              outlined
              dense
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              v-model="date"
            ></v-text-field>
          </template>
          <v-date-picker
          :disabled="isDis"
            v-model="date"
            no-title
            @input="dateMenu1 = false"
          ></v-date-picker>
        </v-menu>
                  
                </v-col>
                <v-col sm="9">
                  <v-text-field dense :disabled="isDis" :label="$t('hr.employee.note')" type="text" outlined
                              v-model="desc"></v-text-field>
                </v-col>
                
                
              </v-row>
            </v-form>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  class="mb-3"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-data-table :dark="$store.state.isDarkMode"
                :headers="headers"
                id="table"
                :search="search"
                class="flex-grow-1"
                :items="data"
                :loading="$store.state.calculations.loading"
                style="border: 1px solid #999"
                hide-default-footer
              >
                <template v-slot:item.rest="{ item }">
                  <v-text-field value="0" v-model="item.rest" dense type="number" class="mt-5" outline
                                :rules="requiredRule"></v-text-field>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
  
    </v-dialog>
  </template>
  
  <script>
  import {requiredRule} from "@/helpers/validation-rules";
  import {mapActions, mapGetters} from "vuex";
  import axios from "axios";
  import debounce from "../../../../helpers/debounce";
  
  export default {
    name: 'PayInstallmentModal',
    data() {
      return {
        dateMenu1: false,
        dateMenu: false,
        localItem: null,
        dialogValue: this.value,
        costCenterItems: [],
        costCenterItemsLoading: false,
        studentSearch: null,
        childrens: [],
        childrensLoading: false,
        search: '',
        valid: false,
        date:new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        desc:'',
        form: {
          created_from:null,
          created_to:null,
          due_from:null,
          due_to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
          invoice:null,
          cost_center:null,
          student: null, 
        },
        paymentSubmit: {
          cash_account_id: '',
        }
      }
    },
    methods: {
      ...mapActions({
        restorePayment: 'payinstallment/restorePayment',
        fetchCashs: 'employees/fetchCashs',
        submitPaymentsAction: 'payinstallment/submitPaymentsAction',
      }),
      async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
      async handleSearch(search) {
        if (search) {
          await this.fetchCashs(search)
        }
      },
      requiredRule() {
        return requiredRule
      },
      async submit() {
        const valid = await this.$refs.form.validate()
        if (valid) {
         let res =  this.restorePayment(this.form);
      
        }
      },
      async submitPayments() {
        const valid = await this.$refs.form2.validate() && await this.$refs.form.validate();
        if (valid) {
           let res =  this.submitPaymentsAction({
            ...this.paymentSubmit,
            document: {
            date:this.date,
            desc:this.desc,
          },
            payments: this.data.map(el => ({id: el.id, salary: el.rest}))
          })
          if(res){
          this.dialogValue = false;
           this.localItem = null;
           this.value = false;
           this.isDis = false;
           this.item=null;
           this.$Notifications(
            "تم تسديد القسط بنجاح",
            { rtl: true, timeout: 1000 },
            "success"
          );
          }
        }
        this.localItem = null;
        this.value = false;
        this.isDis = false;
      },
      cancelPay(){
        this.item=null;
        this.localItem = null;
        this.value = false;
        this.isDis = false;
      }

    },
    mounted() {
      
      this.fetchCashs()
    },
  
    props: {
      value: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default: null
      },
      isDis: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      ...mapGetters({
        data: 'payinstallment/paymentData',
        headers: 'payinstallment/paymentHeaders',
        isThereCalculation: 'payinstallment/isThereCalculation',
        cashs: 'employees/cashs',
      }),
      summary() {
        if(this.isDis){return this.item.payment;}
        return this.data.reduce((p, c) => p + c.rest ?? 0, 0);
      }
    },
    watch: {
      value(newValue) {
      // Update the data property when the prop changes
      this.dialogValue = newValue;
    },
    dialogValue(newValue) {
      // Emit an event to update the prop when the data property changes
      this.$emit('input', newValue);
    },
      item(nV) {
        this.localItem = { ...newValue };
        this.form = {...this.form, ...nV}
      },
      async studentSearch(val) {
            this.childrensLoading = true;
            if (val != '' && val != null) {
                try {
                    const searchResult = await axios.get("/accounting/account/auto-complete", {
                        params: {
                          query: val,
                        },
                    });
                    this.childrens.push(...searchResult.data.data);
                } catch (err) {
                    console.log("err", err);
                } finally {
                    this.childrensLoading = false;
                }
            } else {
                this.childrens = []
            }

        },
    },
   
   async created(){
     
      
      if(this.isDis){
        this.localItem = { ...this.item };
        this.date=this.item.date;
        this.desc=this.item.desc;
       await this.fetchCashs('')
        console.log(this.cashs);
        this.paymentSubmit.cash_account_id = this.item.account.id;
        
      console.log(this.item);
      
      }
      
    },
  
  }
  </script>
  
  <style scoped>
  
  </style>
  